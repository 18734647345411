import { HomeLayout } from "../layouts/HomeLayout";
import * as React from "react";
import authorize404 from "../assets/authorize404.png";
import authorizeImg from "../assets/authorizeImg.png";
import MetatagHelmet from "../components/MetatagHelmet";

const baseName = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : "";
export const Unauthorization = () => {
  const handleGoHome = () => {
    window.history.pushState({}, "Home", baseName);
    window.history.go();
  };
  const mainContentDesktop = (
    <div className={`my-10`}>
      <MetatagHelmet
        title="AgileAP - Automate Accounts Payable and Receivables | Singapore InvoiceNow Compliant"
        description="Automate your Accounts Payable and Receivable processes with AgileAP. Comply with Singapore's InvoiceNow e-invoicing requirements, enhance governance with customisable workflows, role-based access rights, 3-way matching, intelligent analytics, and dashboards. Manage suppliers, customers, quotations, purchase requests, orders, invoices, claims, and timesheet efficiently with AgileAP."
        url={window.location.href}
      />
      <div className={`md:flex md:items-center md:justify-between`}>
        <div className={`md:w-1/2 px-16 md:px-24`}>
          <div className={`mb-4`}>
            <img className={``} src={authorize404} alt={"404"} />
          </div>
          <div className={`font-semibold text-lg text-left flex flex-wrap break-words`}>
            Sorry the page you are looking for does not exist, if you think something is broken report a problem
          </div>
          <div className={`flex justify-start mt-10`}>
            <div onClick={handleGoHome} className={`text-white cursor-pointer bg-primary rounded-3xl py-2 px-10`}>
              Go Home
            </div>
          </div>
        </div>
        <div className={`md:w-1/2`}>
          <img className={`p-10`} src={authorizeImg} alt={"404"} />
        </div>
      </div>
    </div>
  );
  return <HomeLayout showHeader={true} showFooter={true} mainContent={mainContentDesktop} currentPage="Home" />;
};
