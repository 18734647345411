import { useNavigate } from "react-router-dom";
import { AgileAppLogo } from "../assets/svg";
import { isMobile } from "react-device-detect";
import AgileAPLogo from "../assets/AgileAp-Logo-White.png";
import {Helper} from "../utils/helpers";

const baseName = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : "";
export default function Footer() {
  const emailAddress = "info@agileap.com";
  const subject = "Inquiry About AgileAP";
  const body = "Hello,\n\nI am interested in the AgileAP. I would like to request for a demo. \nInfomation: \nTitle: \nFull Name: \nCompany Name: \nEmail: ";
  const navigate = useNavigate();
  const handleClick = () => {
    // if (isMobile) {
      // const phoneNumber = "+65 6684 7044";
      // window.location.href = `tel:${phoneNumber}`;
      //window.open(`https://wa.me/6580880170/?text=${encodeURIComponent(body)}`, "", "noreferrer");
    // } else {
    //   const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    //     body
    //   )}`;
    //   window.location.href = mailtoLink;
    // }
    window.location.href = baseName+"contact";
  };
  const currentYear = new Date().getFullYear();

  return (
    <section
      id="aboutus"
      className="flex flex-col w-full justify-center py-10 sm:py-12 w-lgMain space-y-6 gap-x-32 items-center"
    >
      <div className="flex w-full justify-between gap-1">
        <div className="flex flex-col gap-y-2">
          {/* <AgileAppLogo /> */}
          <img src={AgileAPLogo} width={160} className="-ml-4"/>
          <label className="font-semibold text-sm text-secondary cursor-pointer" onClick={()=>{
            window.open(`https://maps.app.goo.gl/U9dg3AzfwvAhvDLV9`, "", "noreferrer");
          }}>
            24 Sin Ming Lane #08-105 Midview City Singapore 573970
          </label>
          <div className="flex-none sm:flex gap-2">
            <div
              className="font-semibold text-sm text-secondary cursor-pointer"
              onClick={() => {
                window.location.href = "mailto:info@agileap.com";
              }}
            >
              info@agileap.com
            </div>
            <div className="hidden sm:block">|</div>
            <div
              className="font-semibold text-sm text-secondary cursor-pointer"
              onClick={() => {
                window.open(`https://wa.me/6580880170`, "", "noreferrer");
              }}
            >
              +65 8088 0170
            </div>
          </div>
        </div>
        <div className="flex flex-col w-fit align-middle justify-center min-w-fit">
          <button
            onClick={() => {
              Helper.CallAnalyticEvent(window.location.pathname, "Click Contact Us Button");
              handleClick();
            }}
            type="button"
            className="flex font-normal text-white bg-primary rounded-lg hover:ring-0 hover:outline-none hover:bg-opacity-70 active:bg-opacity-50 font-medium rounded-xl lg:text-xl text-md lg:px-8 px-3 py-2 text-center "
          >
            Contact Us
          </button>
        </div>
      </div>
      <hr className="w-full bg-secondary text-secondary text-sm" />
      <div className="flex flex-row w-full justify-between text-xs text-secondary font-semibold">
        <div className="flex">
          <label>© {currentYear} Activants Pte Ltd. - All Rights Reserved</label>
        </div>
        <div className="flex lg:space-x-8 md:space-x-4 space-x-2 justify-between">
          <label
            className="cursor-pointer hover:font-bold hover:text-black active:font-bold active:text-black"
            onClick={(e) => {
              e.stopPropagation();
              // window.open("/terms-conditions", "", "noreferrer");
              window.location.href = "/terms-conditions";
            }}
          >
            Terms of use
          </label>
          <label
            className="cursor-pointer hover:font-bold hover:text-black active:font-bold active:text-black"
            onClick={(e) => {
              e.stopPropagation();
              // window.open("/privacy-policy", "", "noreferrer");
              window.location.href = "/privacy-policy";
            }}
          >
            Privacy policy
          </label>
        </div>
      </div>
    </section>
  );
}
