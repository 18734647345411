import { useEffect, useState } from "react";
import { HomeLayout } from "../layouts/HomeLayout";
import invoiceNow from "../assets/image/e-invoice.png";
import LogoMark4 from "../assets/image/AgileAp-Logo-icon-mark 4.png";
import desktopGirl from "../assets/image/destop-girl.png";
import LogoMark6 from "../assets/image/AgileAp-Logo-icon-mark 6.png";
import LogoMark3 from "../assets/image/AgileAp-Logo-icon-mark 3.png";
import InvoiceLogo from "../assets/image/invoiceLogo.png";
import laptopGril from "../assets/image/laptop-girl.png";
import EInvoice from "../assets/image/einvoice-img.png";
import { AlarmClock, Bullseye, DownArrow, LeftArrow, Receipt } from "../assets/svg";
import LogoMark5 from "../assets/image/AgileAp-Logo-icon-mark 5.png";
import InvoiceNowLGLogo from "../assets/image/InvoiceNow_Logo.png";
import { LuCalendarCheck } from "react-icons/lu";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { PiMoneyWavy } from "react-icons/pi";
import MetatagHelmet from "../components/MetatagHelmet";
import { useNavigate, useParams } from "react-router-dom";

const freqQAndAList = [
  {
    question: "How do I sign up for Peppol (InvoiceNow) e-invoicing? ",
    answer: "Simply approach us at info@agileap.com or info@activants.com",
    index: 0,
  },
  {
    question: "Do I need to register my company for a Peppol ID? If yes, how can I do it? ",
    answer:
      "Certainly, your company must be registered to send and receive Peppol e-invoices. Registration will be handled by Activants (Tickstar Access Point) upon your sign-up. ",
    index: 1,
  },
  {
    question:
      "Given that InvoiceNow utilises the European-based Peppol network, can it still function effectively in Singapore? ",
    answer:
      "Indeed, there is a distinction. Online invoicing typically involves sending a scanned copy of the invoice or a PDF file to the recipient via email. This method relies on manual data entry, which is inherently susceptible to human error. <br><br> On the other hand, InvoiceNow represents a form of e-invoicing wherein invoices are digitally generated within a supplier's financial system. They are then electronically transmitted through the network to the recipient's financial system, where they are processed automatically, without human intervention. This ensures a more secure, accurate, and reliable transmission of data. ",
    index: 2,
  },
  {
    question: "What exactly does e-invoicing entail, and is it secure? ",
    answer:
      "E-invoicing is an automated procedure enabling companies in Singapore to electronically create, send, and receive invoices via specialised software or solutions. This eliminates the necessity for printing and posting traditional paper invoices, thereby streamlining the entire payment process.  <br><br> Conducting e-invoicing transactions on the Peppol network directly links sellers and purchasers. Additionally, e-invoices are transmitted using electronic standards and delivered directly to your Peppol-registered customers in the network, employing industry-standard security protocols like SSL encryption to safeguard personal details from interception by third parties during transmission. ",
    index: 3,
  },
  {
    question: "Can IRAS assess my e-invoices? ",
    answer:
      "No, according to current legal regulations, IRAS cannot access invoices. Nevertheless, you are still required to adhere to any relevant tax regulations stipulated by IRAS. ",
    index: 4,
  },
  {
    question: "Is e-invoicing compulsory in Singapore?  ",
    answer:
      "Although e-invoicing is not obligatory in Singapore, the nation has taken numerous measures to encourage organisations to participate in the Peppol network via the country's dedicated InvoiceNow platform. ",
    index: 5,
  },
];

export default function MyInvoicing() {
  const { country } = useParams();
  const [clickedIndex, setClickedIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(()=>{
    if(country == "sg"){
      navigate(`/${country}/invoicenow`);
    }
  },[])

  const mainContentDesktop = (
    <>
      <MetatagHelmet
        title="InvoiceNow | AgileAP - Automate Accounts Payable and Receivables"
        description="AgileAP is an IMDA accredited solution provider for InvoiceNow. With AgileAP, e-invoices, e-purchase orders and e-credit notes can be exchanged seamlessly and nearly instantaneously to any buyers and suppliers through the InvoiceNow network."
        url={window.location.href}
      />

      <div className="flex flex-col w-full items-center lg:gap-y-16 gap-y-8">
        <section className="flex relative flex-col lg:pt-0 pt-12 lg:flex-row md:flex-row justify-between w-full bg-fairBlue items-center">
          <div className="flex lg:w-1/2 w-full">
            <div className="flex relative ">
              <img src={LogoMark4} alt="" className="w-full top-0 -left-5" />
            </div>
            <img src={LogoMark5} width={"15%"} className="absolute opacity-10 top-0 left-[30%]" />
            <div className="flex flex-col justify-center gap-y-3">
              <label className="text-primary font-bold lg:text-4xl text-2xl">Peppol E-Invoicing is now</label>
              <img src={InvoiceNowLGLogo} className={"lg:w-[70%] w-[60%]"} />
              <img src={LogoMark6} width={"15%"} className="absolute opacity-10  bottom-0 left-[45%]" />
              <p className="text-base text-secondary">
                Grow your business with InvoiceNow by connecting to AgileAP - an IMDA accredited solution provider.
              </p>
            </div>
          </div>
          {/* <images src={LogoMark3} width={160} alt="" className=' lg:top-0 bottom-0 absolute right-0 ' /> */}
          <div className="flex justify-end lg:w-1/2 w-full">
            <img src={desktopGirl} className={"lg:w-full w-4/5"} alt="" />
          </div>
        </section>
        <section className="flex flex-col w-full py-6 lg:px-24 px-8 gap-y-6">
          <div className="flex flex-col">
            {/* <label className='text-primary text-sm font-semibold'>E-INVOICING IN SINGAPORE </label> */}
            <label className="lg:text-3xl text-xl font-black text-black">
              WHAT IS <span className="text-purple">PEPPOL (INVOICENOW) </span>E-INVOICING?
            </label>
          </div>
          <div className="flex flex-col lg:flex-row gap-y-8">
            <div className="flex flex-col gap-y-6 md:w-2/5 lg:w-3/5 md:w-full text-base text-secondary">
              <p>
                InvoiceNow is a nationwide e-invoicing network that enables companies of all sizes to process invoices
                in a more efficient manner, reducing business costs and shortening payment cycles. InvoiceNow operates
                on the open standard Peppol framework, which directly transmits e-invoices in a standard digital format
                across different finance systems.{" "}
              </p>
              <p>
                When both the sender and recipient uses AgileAP, e-invoices, e-purchase orders and e-credit notes can be
                exchanged seamlessly and nearly instantaneously, unlike the traditional method of sending PDF files or
                online invoices via email.
              </p>
              <p>
                This facilitates smoother invoicing, quicker payments, and offers a more environmentally friendly
                solution for both Small and Medium Enterprises (SMEs) and Large Enterprises (LEs).
              </p>
              <p>
                Furthermore, starting from 01 November 2025, GST-registered businesses are mandated to utilise
                InvoiceNow solutions to transmit invoice data to the Inland Revenue Authority of Singapore (IRAS). This
                initiative aligns with national efforts to promote InvoiceNow adoption and integrates tax processes into
                businesses' accounting and payroll systems as part of ongoing digital transformation.
              </p>
            </div>
            <div className="lg:w-2/5 ml-4 p-4 shadow-lg rounded-md">
              <div className=" flex flex-col items-center">
                {/* <span className='text-secondary text-md font-semibold text-center'>Agileap, Supported By</span>
                            <images src={InvoiceLogo} width={"70%"} /> */}
                <div className="w-full">
                  <iframe
                    className="w-full h-[360px]"
                    src="https://www.youtube.com/embed/ypoBEFSCfjk?si=tmK3znwt-dOwarnN"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col w-full py-6 lg:pl-24 lg:px-24 px-8 gap-y-6">
          <div className="flex flex-col">
            {/* <label className='text-primary text-sm font-semibold uppercase'>unraveling challenges : a journey to solution</label> */}
            <label className="lg:text-3xl text-xl font-black text-black">
              WHY USE <span className="text-purple">INVOICENOW</span>?
            </label>
          </div>
          {/* <div className='flex flex-col gap-y-6'>
                    <p className='lg:w-3/5 w-full text-sm text-secondary'>
                        InvoiceNow represents a form of e-invoicing, also known as electronic invoicing, which entails digitally sending invoices. This eliminates the necessity for manual invoicing, which is frequently susceptible to:
                    </p>
                    <div className='flex lg:gap-y-5 gap-x-6 gap-y-9 flex-col lg:flex-row'>
                        <div className='flex flex-col gap-y-6 lg:w-[40%] w-full text-sm text-secondary'>
                            <ul className='flex flex-col gap-y-3 text-sm font-secondary'>
                                <li className='flex gap-x-2'>
                                    <span className='bg-primary rounded-full text-white px-2 text-xs items-center flex'>1</span>Human error
                                </li>
                                <li className='flex gap-x-2'>
                                    <span className='bg-primary rounded-full text-white px-1.5 text-xs items-center flex'>2</span>Piles of paperwork
                                </li>
                                <li className='flex gap-x-2'>
                                    <span className='bg-primary rounded-full text-white px-1.5 text-xs items-center flex'>3</span>Duplicate payments
                                </li>
                                <li className='flex gap-x-2'>
                                    <span className='bg-primary rounded-full text-white px-1.5 text-xs items-center flex'>4</span>Delayed payment approvals
                                </li>
                                <li className='flex gap-x-2'>
                                    <span className='bg-primary rounded-full text-white px-1.5 text-xs items-center flex'>5</span>Manpower shortages
                                </li>
                            </ul>
                            <p className=''>
                                InvoiceNow effectively eliminate these issues by providing fast billing, ensuring clean data, automating processing, facilitating financing and factory operations, and employing an automated process for e-payments.
                            </p>
                        </div>
                        <div className='items-center relative flex justify-center lg:w-3/5 w-full text-sm font-secondary'>
                            <images src={laptopGril} className={"lg:w-4/5 md:w-1/2 w-full"} />
                        </div>
                    </div>

                </div>
            </section>
            <section className='flex flex-col w-full py-6 lg:pl-24 lg:px-24 px-8 gap-y-6'>
                <div className='flex flex-col'>
                    <label className='text-primary text-sm font-semibold uppercase'>PERKS OF USING  INVOICENOW</label>
                    <label className='lg:text-3xl text-xl font-bold text-black'>WHAT ADVANTAGES DOES USING INVOICENOW OFFER? </label>
                </div> */}
          <div className="flex flex-col gap-y-6">
            {/* <div className='lg:w-3/5 w-full flex flex-col gap-y-6 text-sm text-secondary'>
                        <p>The first advantage of using InvoiceNow is the decreased risk of invoices being misplaced in transit. By utilising e-invoicing, invoices are directly transmitted to the recipient's accounting system, virtually eliminating the possibility of loss.</p>
                        <p>Furthermore, the Peppol Ready Solution aids in streamlining administrative and manual tasks. </p>
                        <p>Ultimately, the end goal for the majority of companies is expedited payment. By facilitating convenient payment options for customers through e-invoicing and promptly issuing invoices, companies experience faster payment cycles. </p>
                        <p>Here are additional benefits derived from utilising InvoiceNow.</p>
                    </div> */}
            <div className="flex flex-col lg:flex-row gap-y-8 lg:gap-x-6 w-full lg:w-[90%]">
              <div className="flex flex-col w-full items-center justify-center">
                <div className="flex flex-col gap-y-2 text-sm text-secondary">
                  <div className="flex gap-x-6">
                    <div className="w-fit flex items-start pt-4">
                      <span className="rounded-md flex bg-darkPurple lg:p-2 p-1.5">
                        <AlarmClock className="w-5 flex" />
                      </span>
                    </div>
                    <div className="flex flex-col gap-y-2 pt-2">
                      <span className="font-semibold lg:text-xl text-lg text-black">Fast Billing</span>
                      <span className="text-secondary text-lg">
                        Quicker generation of e-invoice and no latency due to postage or courier delays supplier’s end.
                      </span>
                      <span className="text-base">
                        At the supplier's end, generating an e-invoice from the Accounts Payable system is efficient,
                        aided by dropdown menus and readily available customer and product information. This process is
                        significantly quicker, especially if there’s existing order information in the system, compared
                        to manual data entry, which can take hours or days as it often involves searching for physical
                        documents.
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-x-6">
                    <div className="w-fit flex items-start pt-4">
                      <span className="rounded-md flex bg-darkPurple lg:p-2 p-1.5">
                        <Receipt className="w-5 h-5 flex" fill="#fff" />
                      </span>
                    </div>
                    <div className="flex flex-col gap-y-2 pt-2">
                      <span className="font-semibold lg:text-xl text-lg text-black">Clean Data</span>
                      <span className="text-secondary text-lg">
                        Minimised manual intervention with structured data format reduces human errors.
                      </span>
                      <span className="text-base">
                        Processing a manual invoice can be time-consuming, often taking several days before the data is
                        input into a system. However, by utilising e-invoices transmitted via the Peppol network in a
                        standardised format, invoices can be received directly into the Accounts Payable system. This
                        eliminates the need for manual data entry or scanning, reducing the risk of data errors,
                        manipulation, and fraud.
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-x-6">
                    <div className="w-fit flex items-start pt-4">
                      <span className="rounded-md flex bg-darkPurple lg:p-2 p-1.5 ">
                        <Bullseye className="w-5 flex" />
                      </span>
                    </div>
                    <div className="flex flex-col gap-y-2 pt-2">
                      <span className="font-semibold lg:text-xl text-lg text-black">Automated Processing</span>
                      <span className="text-secondary text-lg">
                        Straight-through processing and automatic discovery of anomalies and fraud using AI on network
                        data secured trhough enctyption
                      </span>
                      <span className="text-base">
                        Complex e-invoices undergo thorough 3-way or 4-way matching with supporting data to expedite
                        user verification, aided by Artificial Intelligence in detecting anomalies or potential fraud.
                        Errors at this stage can cause significant delays, necessitating invoice re-issuance, which
                        could take one week to a month. However, automation in e-invoicing enables immediate routing
                        through an approval matrix, with notifications and real-time dashboards facilitating close
                        monitoring to minimise delays.
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-x-6">
                    <div className="w-fit flex items-start pt-4">
                      <span className="rounded-md flex bg-darkPurple lg:p-2 p-1.5 ">
                        <LuCalendarCheck className="w-5 h-5 flex" color="#fff" />
                      </span>
                    </div>
                    <div className="flex flex-col gap-y-2 pt-2">
                      <span className="font-semibold lg:text-xl text-lg text-black">Future Proofing</span>
                      <span className="text-lg text-secondary">
                        Get your company ready before Vendors@Gov is phased out.
                      </span>
                      <span className="text-base">
                        The Government will implement InvoiceNow as the default e-invoice submission channel for all
                        Government vendors within the next few years. Eventually, InvoiceNow will replace the
                        Vendors@Gov portal.
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-x-6">
                    <div className="w-fit flex items-start pt-4">
                      <span className="rounded-md flex bg-darkPurple lg:p-2 p-1.5 ">
                        <HiOutlineReceiptTax className="w-5 h-5 flex" color="#fff" />
                      </span>
                    </div>
                    <div className="flex flex-col gap-y-2 pt-2">
                      <span className="font-semibold lg:text-xl text-lg text-black">Tax Compliance</span>
                      <span className="text-secondary text-sm">
                        Comply to IRAS requirement for GST-registered businesses.
                      </span>
                      <span className="text-xs">
                        Starting from 01 November 2025, GST-registered businesses are mandated to utilise InvoiceNow
                        solutions to transmit invoice data to the Inland Revenue Authority of Singapore (IRAS).
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-x-6">
                    <div className="w-fit flex items-start pt-4">
                      <span className="rounded-md flex bg-darkPurple lg:p-2 p-1.5 ">
                        <PiMoneyWavy className="w-5 h-5 flex" color="#fff" />
                      </span>
                    </div>
                    <div className="flex flex-col gap-y-2 pt-2">
                      <span className="font-semibold lg:text-xl text-lg text-black">Available Grants</span>
                      <span className="text-lg text-secondary">
                        Get rewarded by signing up with AgileAP and starts sending e-invoices today.
                      </span>
                      <span className="text-base">
                        Various incentives and grants, such as the InvoiceNow Transaction Bonus, which gives $200 to
                        businesses that send 10 e-invoices, are available to help businesses defray the cost of adoption
                        and utilisation of InvoiceNow.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='items-center flex lg:justify-end justify-center w-full'>
                            <images src={EInvoice} className={"lg:w-5/6 md:w-3/5 w-full"} style={{objectFit: "cover"}} />
                        </div> */}
            </div>
          </div>
        </section>
        {/* <section className='flex flex-col w-full lg:pl-24 lg:px-24 px-8 gap-y-6'>
                <label className='lg:text-3xl text-xl text-center font-bold text-black'>Frequently Asked Questions</label>
                <div className='flex flex-col  items-center gap-y-6'>
                    {
                        freqQAndAList.map((item, index) =>
                            <div className={` ${clickedIndex === index ? "border-2 border-deepBlue" : ""} flex shadow-md justify-between lg:p-6 rounded-lg lg:w-4/6 w-full p-4`}>
                                <div className='flex flex-col gap-y-1 w-4/5 '>
                                    <span className='font-semibold text-lg sm:text-md'>{item.question} </span>
                                    {
                                        clickedIndex === index &&
                                        <span className='lg:text-sm text-secondary text-xs' dangerouslySetInnerHTML={{ __html: item.answer }}></span>
                                    }
                                </div>
                                <div className='w-1/5 flex h-fit justify-end'>
                                    {
                                        clickedIndex === index ?
                                            <span className='rounded-full flex bg-deepBlue shadow-lg py-3 px-2 sm:px-2.5 items-center cursor-pointer' onClick={() => setClickedIndex(index)}>
                                                <DownArrow className='w-3.5 sm:w-3 flex' />
                                            </span>
                                            :
                                            <span className='rounded-full flex bg-white shadow-lg py-3 px-3.5 items-center cursor-pointer' onClick={() => setClickedIndex(index)}>
                                                <LeftArrow className='w-2 flex' />
                                            </span>
                                    }

                                </div>
                            </div>
                        )
                    }


                </div>
            </section> */}
      </div>
    </>
  );

  return <HomeLayout showHeader={true} showFooter={true} mainContent={mainContentDesktop} currentPage="e-invoicing" />;
}
