import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "../components/useAnalyticsEventTracker";

const AnalyticPageView = (path: string) => {
    ReactGA.send({ hitType: "pageview", page: path, title: "Custom Title" });
};

const CallAnalyticEvent = (path: string, action: string, label?: string) => {
    const gaEventTracker = useAnalyticsEventTracker(path);
    gaEventTracker(action, path);
};

export const Helper = {
    CallAnalyticEvent,
    AnalyticPageView,
}