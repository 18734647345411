import { useEffect, useState } from "react";
import { HomeLayout } from "../layouts/HomeLayout";
import invoiceNow from "../assets/image/e-invoice.png";
import LogoMark4 from "../assets/image/AgileAp-Logo-icon-mark 4.png";
import desktopGirl from "../assets/image/destop-girl.png";
import LogoMark6 from "../assets/image/AgileAp-Logo-icon-mark 6.png";
import LogoMark3 from "../assets/image/AgileAp-Logo-icon-mark 3.png";
import InvoiceLogo from "../assets/image/invoiceLogo.png";
import laptopGril from "../assets/image/laptop-girl.png";
import EInvoice from "../assets/image/einvoice-img.png";
import { AlarmClock, Bullseye, DownArrow, LeftArrow, Receipt } from "../assets/svg";
import LogoMark5 from "../assets/image/AgileAp-Logo-icon-mark 5.png";
import InvoiceNowLGLogo from "../assets/image/InvoiceNow_Logo.png";
import { LuCalendarCheck } from "react-icons/lu";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { PiMoneyWavy } from "react-icons/pi";
import MetatagHelmet from "../components/MetatagHelmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiLogin } from "../services";
import { format } from "date-fns";
import { MdOutlineKeyboardDoubleArrowRight, MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { isMobileOnly } from "react-device-detect";

type subSectionType = {
  id: number;
  url: string;
  title: string;
  docs: Array<docType>;
  sequenceId: number;
  dtSchedule: string;
};

type docType = {
  header: string;
  html: any;
};

export default function PressRelease() {
  const location = useLocation();
  const { country, url } = useParams();
  const [clickedIndex, setClickedIndex] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [categoryList, setCategoryList] = useState<Array<subSectionType>>();
  const [selectedCategory, setSelectedCategory] = useState<subSectionType>();
  const [htmlContent, setHtmlContent] = useState("");
  const [baseName, setBaseName] = useState(process.env.REACT_APP_IMAGE_URL ? process.env.REACT_APP_IMAGE_URL : "");

  useEffect(() => {
    if (url) {
      console.log(url, "dahsjdkhkajdsha");
      retrieveSections(0, url);
    } else retrieveCategoryList();
  }, []);

  const retrieveCategoryList = async () => {
    setIsLoading(true);
    await apiLogin("admin/retrieve-pr-section-list")
      .post({
        id: null,
        authenticated: true,
        body: { skipAuthentication: true },
      })
      .then((res: any) => {
        console.log(res, "aoiugresdefd");
        if (!url) {
          setSelectedCategory(undefined);
          setSelectedCategoryId(0);
        }
        setIsLoading(false);
        if (res && res.data.response) {
          setCategoryList(res.data.response);
          if (!url && res.data.response.length > 0) {
            setSelectedCategory(res.data.response[0]);
            retrieveSections(res.data.response[0].id, "");
          }
        }
      })
      .catch((err: any) => {
        console.log(err, "asdfargtersd");
      });
  };

  const retrieveSections = async (sectionId: number, url: string) => {
    setIsLoading(true);
    await apiLogin("admin/retrieve-pr-documentation")
      .post({
        id: null,
        authenticated: false,
        body: { id: sectionId, skipAuthentication: true, url: url },
      })
      .then((res: any) => {
        setIsLoading(false);
        console.log(res.data.response, "dshjadkhsa");
        if (res && res.data.response) {
          setSelectedCategoryId(res.data.response[0].id);
          setHtmlContent(res.data.response[0].docs[0].html);
        }
        retrieveCategoryList();
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    if (selectedCategory && selectedCategory.url) {
      window.history.pushState(
        {},
        selectedCategory.title,
        baseName + country + "/press-release/" + selectedCategory.url
      );
      window.history.go();
    }
  }, [selectedCategory]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  console.log("isOpenPopup : ", isOpenPopup, selectedCategoryId);
  const mainContentDesktop = (
    <>
      <MetatagHelmet
        title="AgileAP - Press Release | Singapore InvoiceNow Compliant"
        description="Automate your Accounts Payable and Receivable processes with AgileAP. Comply with Singapore's InvoiceNow e-invoicing requirements, enhance governance with customisable workflows, role-based access rights, 3-way matching, intelligent analytics, and dashboards. Manage suppliers, customers, quotations, purchase requests, orders, invoices, claims, and timesheet efficiently with AgileAP."
        url={window.location.href}
      />

      <div className="flex flex-col w-full items-center lg:gap-y-16 gap-y-8">
        <div className="md:flex w-full">
          <div className={`${isMobileOnly ? "hidden" : "hidden md:block"}  w-1/5 pt-10 pl-4 pr-2`}>
            <div className="sticky top-20">
              <div className="font-extrabold text-xl pl-2 pb-6">Press Release</div>
              {categoryList?.map((e, index) => (
                <div
                  onClick={() => {
                    setSelectedCategoryId(e.id);
                    if (e) {
                      setSelectedCategory(e);
                      retrieveSections(e.id, "");
                    }
                  }}
                  className={`flex flex-col p-2 cursor-pointer hover:text-primary flex rounded-md ${
                    e.id == selectedCategoryId ? "text-primary bg-primary bg-opacity-20" : "text-black"
                  }`}
                >
                  <div className="text-xs bg-primary bg-opacity-50 w-fit px-2 rounded-md text-white mb-1.5">
                    {format(new Date(e.dtSchedule), "MMM yyyy")}
                  </div>
                  <div className="text-sm break-words">{e.title}</div>
                </div>
              ))}
            </div>
          </div>
          <div className=" md:hidden w-full fixed z-20 relative">
            <div className="fixed top-24 z-[39]">
              <div
                className={`flex ${
                  isOpenPopup ? "w-8" : "w-10"
                } bg-white justify-center cursor-pointer font-extrabold text-xl text-center p-1 border-2 boder-l-0 border-gray-200 rounded-tr-lg rounded-br-lg ${
                  !isOpenPopup && "shadow-md"
                }`}
                onClick={()=>setIsOpenPopup(!isOpenPopup)}
              >
                {/*<span>Press Release</span>*/}
                {isOpenPopup ? <MdOutlineKeyboardDoubleArrowLeft /> : <MdOutlineKeyboardDoubleArrowRight />}
              </div>
            </div>
            <div
              className={`${
                !isOpenPopup && "hidden"
              } z-30 top-20 mt-2 shadow-2xl border-2 mx-4 border-gray-200 rounded-md bg-white py-5 px-5 fixed`}
            >
              <div className="font-extrabold text-xl mb-2">Press Release</div>
              {categoryList?.map((e, index) => (
                <div
                  onClick={() => {
                    setIsOpenPopup(false);
                    setSelectedCategoryId(e.id);
                    if (e) {
                      setSelectedCategory(e);
                      retrieveSections(e.id, "");
                    }
                  }}
                  className={`flex flex-col p-2 cursor-pointer hover:text-primary flex rounded-md ${
                    e.id == selectedCategoryId ? "text-primary bg-primary bg-opacity-20" : "text-black"
                  }`}
                >
                  <div className="text-xs bg-primary bg-opacity-50 w-fit px-2 rounded-md text-white mb-1.5">
                    {format(new Date(e.dtSchedule), "MMM yyyy")}
                  </div>
                  <div className="text-sm break-words">{e.title}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full md:w-4/5">
            <div className="bg-[#F4F4F4]">
              <div className="md:px-[50px] md:py-[50px] p-5 pt-8" dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <HomeLayout showHeader={true} showFooter={true} mainContent={mainContentDesktop} currentPage="Press Release" />
  );
}
