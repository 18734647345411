import React from "react";
import ReactGA from "react-ga4";

const useAnalyticsEventTracker = (category="Landing") => {
    const eventTracker = (action = "test action", label = "test label") => {
        //ReactGA.event({category, action, label});
       // ReactGA.set({ userId });
        //ReactGA.set({ userId: userId });
        console.log("Action ===> ", action, label);
        ReactGA.event({
            category: category,
            action: action,
            label: label, // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
        });
    }
    return eventTracker;
}
export default useAnalyticsEventTracker;